// TODO: why reason and remarks are different? They look the same to me.
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import I18n from '../base/i18n';
import axios from '../base/axios';
import TextArea from '../base/textarea';
import CancelAndSaveButton from './CancelAndSaveButton';
import UserSignature from '../commons/user_signature';
import CommentBadge from '../commons/comment_badge';

const Remark = ({
  remove,
  remarkableId,
  remarkableType,
  userCanModifyRemark,
  remark: propRemark,
  lastRemarkOfRemarkable
}) => {
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remark, setRemark] = useState(propRemark);
  const [editMode, setEditMode] = useState(!remark);
  const [text, setText] = useState(remark?.text || '');

  const save = () => {
    setLoading(true);
    if (remark) {
      // update
      axios({
        method: 'patch',
        url: `/remarks/${remark.id}`,
        data: {
          text
        }
      }).then(({ data }) => {
        setRemark(data);
        setEditMode(false);
        setLoading(false);
      });
    } else {
      // create
      axios({
        method: 'post',
        url: '/remarks',
        data: {
          text,
          remarkable_id: remarkableId,
          remarkable_type: remarkableType
        }
      }).then(({ data }) => {
        setRemark(data);
        setEditMode(false);
        setLoading(false);
      });
    }
  };

  const hide = () => {
    if (confirm(I18n.t('are_you_sure'))) {
      setHidden(true);
      remove();
    }
  };

  const canModify = () => userCanModifyRemark && remark.current_user_can_modify;

  const deleteRequest = () => {
    setLoading(true);
    axios({
      method: 'delete',
      url: `/remarks/${remark.id}`,
      headers: { accept: 'application/javascript' }
    }).then((data) => {
      setHidden(true);
      setLoading(false);
      // execute server side js
      eval(data.data);
      remove();
    });
  };

  const deleteRemark = () => {
    if (lastRemarkOfRemarkable) {
      if (confirm(I18n.t('remarks.delete.last.are_you_sure'))) {
        deleteRequest();
      }
    } else if (confirm(I18n.t('are_you_sure'))) {
      deleteRequest();
    }
  };

  if (hidden) return <></>;

  const noText = text.trim() === '';

  if (!remark) {
    return (
      <>
        <TextArea text={text} noText={noText} setText={setText} />

        <CancelAndSaveButton
          onSave={save}
          onCancel={hide}
          disabled={noText || loading}
          userCanModifyRemark={userCanModifyRemark}
        />
      </>
    );
  }

  if (editMode) {
    return (
      <>
        <TextArea text={text} noText={noText} setText={setText} />

        <CancelAndSaveButton
          onSave={save}
          disabled={noText || loading}
          onCancel={() => setEditMode(false)}
          userCanModifyRemark={userCanModifyRemark}
        />
      </>
    );
  }

  // display mode
  return (
    <>
      <div className={`displayable${canModify() ? ' mb-1' : ''}`}>
        <div
          className="displayable-text"
          dangerouslySetInnerHTML={{ __html: remark.display_text }}
        />
        <div className="date-label">
          {remark.date}
          <div className="text-end">
            {remark.comment_type && (
              <CommentBadge commentType={remark.comment_type} />
            )}
            {remark.display_user_signature && (
              <UserSignature
                userAvatarUrl={remark.user_avatar_url}
                userShortNameOrEmail={remark.user_short_name_or_email}
                userFullNameOrEmail={remark.user_full_name_or_email}
              />
            )}
          </div>
        </div>
      </div>
      {canModify() && (
        <div className="row justify-content-end mb-3">
          <div className="col-auto">
            <div className="btn-group opacity-75">
              <button
                type="button"
                onClick={() => setEditMode(true)}
                className="btn btn-xs btn-outline-primary"
                data-tippy-content={I18n.t('assessment_questions.details.change_remark')}
              >
                <i className="fa fa-pen fa-sm" />
              </button>

              <button
                type="button"
                onClick={() => deleteRemark()}
                className="btn btn-xs btn-outline-danger"
                data-tippy-content={I18n.t('destroy')}
              >
                <i className="fa fa-trash fa-sm" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Remark.defaultProps = {
  remark: null
};

Remark.propTypes = {
  remove: PropTypes.func.isRequired,
  remark: PropTypes.instanceOf(Object),
  remarkableId: PropTypes.string.isRequired,
  remarkableType: PropTypes.string.isRequired,
  userCanModifyRemark: PropTypes.bool.isRequired,
  lastRemarkOfRemarkable: PropTypes.bool.isRequired
};

export default Remark;
